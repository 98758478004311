import { Endpoint } from '@common/interface/endpoint.interface';

export const ENDPOINT: Endpoint = {
  forgotPassword: 'api-portal/oauth/v2/forgot-password',
  login: 'api-oauth/web/v1/login',
  categoryList: 'api-portal/merchant/v3/product-category',
  item: 'api-portal/merchant/v3-1/item',
  itemList: 'api-portal/merchant/v3-1/custom-menu/list-item',
  storeList: 'api-portal/idepos/store',
  phoneCodeList: 'api-portal/utilities/phone-number',
  transaction: 'api-loyalty/merchant/transaction',
  report: 'api-loyalty/merchant/report',
  voucher: 'api-loyalty/merchant/voucher',
  voucherList: 'api-loyalty/merchant/voucher/list',
  voucherUsage: (id: string) => `api-loyalty/merchant/voucher/${id}/usage`,
  point: 'api-loyalty/merchant/point',
  sms: 'api-sms/config/saveConfig',
  smsList: 'api-sms/config/list/merchant',
  smsUpdate: 'api-sms/config/updateConfig',
  setting: 'api-membership/v1/settings',
  member: 'api-membership/v1/members',
  loyaltyMember: 'api-loyalty/merchant/member',
  dashboard: 'api-loyalty/merchant/dashboard',
  merchantInfo: 'api-portal/oauth-account/info',
  announcementTemplate: 'api-membership/announcement-config',
  announcementStaus: 'api-membership/announcement-global-config',
  idePosOrder: 'api-portal/idepos/merchant/v3/order',
};
